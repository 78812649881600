
import {defineComponent, ref, computed, watch, PropType} from 'vue';
import {useI18n} from 'vue-i18n';
import {useStore} from 'vuex';
import {injectOrThrow} from "@/helpers/inject";
import {Enterprises, Groups} from "@/models";
import {useForm, useField} from 'vee-validate';
import List from "@/views/components/directory/list.vue";
import {ucFirst} from '@/helpers/firstRegister';

export default defineComponent({
	emits: ["dialog-closed", "item-changed"],
	props: {
		open: {
			type: Boolean,
			default: false
		},
		item: {
			type: Object as PropType<Enterprises.Enterprise>,
			required: true
		}
	},
	components: {
		List
	},
	setup(props, {emit}) {
		const { t } = useI18n();
		const store = useStore();

		const {handleSubmit, handleReset, setValues, values} = useForm();

		const selectedGroups = ref({} as Groups.Group)
		const groups = computed(() => store.state.dictionaries.groups as Groups.Group[]);
		function selectGroups(selected: Groups.Group) {
			selectedGroups.value = selected ?? {};
		}

		const { value: name, errorMessage: nameError } = useField<string>('name', 'required');
		const { value: address, errorMessage: addressError } = useField<string>('address', 'required');

		const other = ref({} as {[key: string]: any});
		const otherError = ref<string>("");

		const set = () => {
			setValues({
				name: props.item?.name || "",
				address: props.item?.address || ""
			});

			if(props.item.id_group) {
				const findGroup = groups.value.find(group => group.id === props.item.id_group);

				if(findGroup)
					selectedGroups.value = findGroup;
			}

			if(props.item.other)
				other.value = typeof props.item.other === 'string' ? JSON.parse(props.item.other) : props.item.other;
		}

		set();

		watch(() => props.open, () => set());

		const isLoading = ref(false);
		const service = injectOrThrow<Enterprises.EnterpriseService>('Enterprises.EnterpriseService');

		const edit = handleSubmit(async () => {
			if(otherError.value.length > 0) return;

			isLoading.value = true;

			const id_group = selectedGroups.value.id ? {id_group: selectedGroups.value.id} : {};

			try {
				await service.edit({
					id: props.item.id,
					name: values.name,
					address: values.address,
					other: other.value,
					...id_group
				});

				store.commit('notification/successMessage', {title: t("form.notify.success.edited", { name: ucFirst(t("dictionary.enterprise")) })});
				emit("item-changed");
				closeModal();
			} catch (e) {
				return;
			} finally {
				isLoading.value = false;
			}
		});

		const closeModal = async () => {
			emit('dialog-closed');
			handleReset();
		};

		return {
			t,
			groups, selectedGroups, selectGroups,
			name, nameError,
			address, addressError,
			other, otherError,
			isLoading, edit, closeModal
		}
	}
})
