
import {defineComponent, ref, computed} from 'vue';
import {useI18n} from 'vue-i18n';
import {useStore} from 'vuex';
import {injectOrThrow} from "@/helpers/inject";
import {Enterprises, Groups} from "@/models";
import {useForm, useField} from 'vee-validate';
import List from "@/views/components/directory/list.vue";
import {ucFirst} from '@/helpers/firstRegister';

export default defineComponent({
	emits: ["dialog-closed", "item-added"],
	props: {
		open: {
			type: Boolean,
			default: false
		}
	},
	components: {
		List
	},
	setup(_, {emit}) {
		const { t } = useI18n();
		const store = useStore();

		const {handleSubmit, handleReset, values} = useForm();

		const selectedGroups = ref({} as Groups.Group|null)
		const groups = computed(() => store.state.dictionaries.groups as Groups.Group[]);
		function selectGroups(selected: Groups.Group) {
			selectedGroups.value = selected ?? {};
		}

		const { value: name, errorMessage: nameError } = useField<string>('name', 'required');
		const { value: address, errorMessage: addressError } = useField<string>('address', 'required');

		const other = ref({} as {[key: string]: any});
		const otherError = ref<string>("");

		const isLoading = ref(false);
		const service = injectOrThrow<Enterprises.EnterpriseService>('Enterprises.EnterpriseService');

		const add = handleSubmit(async () => {
			if(otherError.value.length > 0) return;

			isLoading.value = true;

			const id_group = selectedGroups.value?.id ? {id_group: selectedGroups.value.id} : {};

			try {
				await service.add({
					id: 0,
					name: values.name,
					address: values.address,
					other: other.value,
					...id_group
				});

				store.commit('notification/successMessage', {title: t("form.notify.success.added", { name: ucFirst(t("dictionary.enterprise")) })});
				emit("item-added");
				closeModal();
			} catch (e) {
				return;
			} finally {
				isLoading.value = false;
			}
		});

		const closeModal = () => {
			emit('dialog-closed');
			handleReset();

			selectedGroups.value = null;
			other.value = {};
			otherError.value = '';
		}

		return {
			t,
			groups, selectedGroups, selectGroups,
			name, nameError,
			address, addressError,
			other, otherError,
			isLoading, add, closeModal
		}
	}
})
