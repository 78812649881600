import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container my-4" }
const _hoisted_2 = { class: "row tablet-show-ai-center mb-4 tablet-hide-fd-column-reverse sticky" }
const _hoisted_3 = { class: "col py-0" }
const _hoisted_4 = { class: "col-auto py-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_button = _resolveComponent("app-button")!
  const _component_data_table = _resolveComponent("data-table")!
  const _component_add = _resolveComponent("add")!
  const _component_edit = _resolveComponent("edit")!
  const _component_remove = _resolveComponent("remove")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.t("title.enterprises")), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_app_button, {
          block: "",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isAddOpened = true))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("button.add")) + " " + _toDisplayString(_ctx.t("dictionary.enterprise")), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _createVNode(_component_data_table, {
      list: _ctx.list,
      loading: _ctx.loading,
      onRowClicked: _ctx.edit,
      onPageChanged: _ctx.changePage,
      onItemRemoved: _ctx.remove,
      onListUpdated: _cache[1] || (_cache[1] = ($event: any) => (_ctx.load()))
    }, null, 8, ["list", "loading", "onRowClicked", "onPageChanged", "onItemRemoved"]),
    _createVNode(_component_add, {
      open: _ctx.isAddOpened,
      onDialogClosed: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isAddOpened = false)),
      onItemAdded: _cache[3] || (_cache[3] = ($event: any) => (_ctx.load()))
    }, null, 8, ["open"]),
    _createVNode(_component_edit, {
      open: _ctx.isEditOpened,
      onDialogClosed: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isEditOpened = false, _ctx.item = {})),
      item: _ctx.item,
      onItemChanged: _cache[5] || (_cache[5] = ($event: any) => (_ctx.load()))
    }, null, 8, ["open", "item"]),
    _createVNode(_component_remove, {
      open: _ctx.isRemoveOpened,
      onDialogClosed: _cache[6] || (_cache[6] = ($event: any) => (_ctx.isRemoveOpened = false, _ctx.item = {})),
      id: _ctx.item.id,
      name: _ctx.t("dictionary.enterprise"),
      service: _ctx.service,
      onItemRemoved: _cache[7] || (_cache[7] = ($event: any) => (_ctx.load({pageNumber: 1})))
    }, null, 8, ["open", "id", "name", "service"])
  ]))
}