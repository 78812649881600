
import {defineComponent, ref, onMounted, watch} from 'vue';
import {useI18n} from 'vue-i18n';
import {useStore} from 'vuex';
import {List} from "@/components/tables/DataTable";
import DataTable from "./components/listing/DataTable.vue";
import {injectOrThrow} from "@/helpers/inject";
import {Enterprises, Searches} from "@/models";
import Add from "./Add.vue";
import Edit from "./Edit.vue";
import Remove from "@/views/components/dialogs/Remove.vue";
import {setCookie} from '@/helpers/cookie';
import {useRoute} from "vue-router";

export default defineComponent({
	components: {
		DataTable,
		Add,
		Edit,
		Remove
	},
	setup() {
		const { t } = useI18n();
		const route = useRoute();
		const { state, commit, dispatch } = useStore();

		const changePage = (pagination: List) => {
			load({
				pageNumber: pagination.current,
				pageSize: pagination.size
			})

			commit('globals/setPageSize', pagination.size);
			setCookie("pageSize", pagination.size);
		};

		const list = ref(route.name === 'searches'
			? new Searches.SearchList({ size: state.globals.pageSize })
			: new Enterprises.EnterpriseList({ size: state.globals.pageSize })
		);
		const query = ref<Enterprises.EnterpriseQueryParams>({ pageSize: state.globals.pageSize });
		const service = injectOrThrow<Enterprises.EnterpriseService>('Enterprises.EnterpriseService');
		const searchService = injectOrThrow<Searches.SearchService>('Searches.SearchService');

		const loading = ref(true);
		const load = async (params: Enterprises.EnterpriseQueryParams) => {
			loading.value = true;
			query.value = { ...query.value, ...params, ...route.query };
			list.value = route.name === 'searches'
				? await searchService.search(query.value)
				: await service.list(query.value)
			loading.value = false;
		}

		onMounted(async () => {
			await dispatch('dictionaries/getGroups');
			await load(query.value);
		});

		watch(() => route.query, () => {
			if(Object.keys(route.query).length)
				load(query.value)
		});

		const isAddOpened = ref(false);

		const item = ref({} as Enterprises.Enterprise);

		const isEditOpened = ref(false);
		const edit = (value: Enterprises.Enterprise) => {
			item.value = value;
			isEditOpened.value = true;
		};

		const isRemoveOpened = ref(false);
		const remove = (value: Enterprises.Enterprise) => {
			item.value = value;
			isRemoveOpened.value = true;
		};

		return {
			t, service,
			loading, load, changePage,
			list, item,
			isAddOpened,
			isEditOpened, edit,
			isRemoveOpened, remove
		}
	}
})
